import { groq } from "next-sanity";
// import { navigationLink } from "./navigationLink.groq";

export const navigationLinksQuery = groq`*[_type == "navigationLinks"][-1]{
  navigationLinks[]{
    label,
		internalLink->{
			_type,
			"slug": slug.current,
		},
		hasSublinks,
		sublinks[]{
			label,
			internalLink->{
				_type,
				"slug": slug.current,
			},
			hasSublinks,
			sublinks[]{
				label,
				internalLink->{
					_type,
					"slug": slug.current,
				},
				hasSublinks,
				sublinks[]{
					label,
					internalLink->{
						_type,
						"slug": slug.current,
					},
				}
			},
		},
  },
}`;
