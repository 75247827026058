import { AppLink } from "@otomoro/components/shared/AppLink";
import { resolveHref } from "@otomoro/sanity/lib/sanity.links";
import { styles } from "./SubLinks.css";
import { ArrowIcon } from "@otomoro/components/icons";

type SubLinksProps = {
	links: Sanity.Default.Schema.NavigationLink[];
	setIsSubMenuOpen: (value: boolean | number) => void;
};

export const SubLinks = ({ links, setIsSubMenuOpen }: SubLinksProps) => {
	const { base, list, item, icon } = styles()

	return (
		<div className={base()}>
			<ul className={list()}>
				{links.map((link) => (
					link?.internalLink && (
						<li key={link?.label} className={item()}>
							<AppLink
								textProps={{
									size: 'h3',
									textTransform: 'uppercase',
									className: 'block py-[1.5rem] !font-condensed transition-colors duration-200 hover:text-mainPink',
								}}
								onClick={() => setIsSubMenuOpen(false)}
								// @ts-ignore
								href={resolveHref(link?.internalLink?._type, link?.internalLink?.slug as any)}
								aria-label={`Go to: ${link?.label}`}>
									<>
										{link?.label}
										<ArrowIcon className={icon()} />
									</>
							</AppLink>
						</li>
					)
				))}
			</ul>
		</div>
	);
};
