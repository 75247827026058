type Props = {
  theme?: 'light' | 'dark'
}

export function YouTubeIcon({ theme = 'light' }: Props) {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5547 2.66264C20.3153 1.76174 19.6104 1.05159 18.7147 0.810545C17.0924 0.373535 10.5849 0.373535 10.5849 0.373535C10.5849 0.373535 4.07747 0.373535 2.45429 0.810545C1.55946 1.05159 0.854522 1.76087 0.615207 2.66264C0.179932 4.29622 0.179932 7.70386 0.179932 7.70386C0.179932 7.70386 0.179932 11.1115 0.615207 12.7451C0.854522 13.646 1.55946 14.3561 2.45516 14.5972C4.07747 15.0342 10.5849 15.0342 10.5849 15.0342C10.5849 15.0342 17.0924 15.0342 18.7156 14.5972C19.6104 14.3561 20.3153 13.6468 20.5555 12.7451C20.9899 11.1115 20.9899 7.70386 20.9899 7.70386C20.9899 7.70386 20.9899 4.29622 20.5547 2.66264ZM8.45711 10.7985V4.60924L13.8955 7.70386L8.45711 10.7985Z"
				className="transition-all"
        fill={theme === 'dark' ? "#828282" : "#000000"}
      />
    </svg>
  )
}
