import { tv } from "@otomoro/helpers/mergeClasses";
import { VariantProps } from "tailwind-variants";

export const styles = tv({
	slots: {
		wrapper: 'w-full h-full flex flex-col align-start pt-[7.5rem] px-2',
		list: 'mt-auto w-full flex flex-col divide-y-[1.5px] pb-[3.5rem]',
		listItem: 'py-[1.5rem]',
		button: 'appearance-none flex items-center pt-2',
		icon: 'rotate-180 w-[1.2rem] mr-[0.6rem]'
	},
});

export type ApplicationsVariants = VariantProps<typeof styles>
