import(/* webpackMode: "eager" */ "/vercel/path0/app/(client)/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(client)/preview/LiveVisualEditing.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/layout/Footer/Footer.layout.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/layout/Footer/Footer.preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/layout/Navigation/Navigation.layout.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/layout/Navigation/Navigation.preview.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-translate/lib/esm/AppDirI18nProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"styles/fonts/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./FamiljenGrotesk-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"}],\"variable\":\"--font-familjen-grotesk\",\"display\":\"swap\"}],\"variableName\":\"familjenGrotesk\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"styles/fonts/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./HelveticaNeueLTStd-BdCn.woff2\",\"weight\":\"600\",\"style\":\"normal\"}],\"variable\":\"--font-helvetica-neue-condensed\",\"display\":\"swap\"}],\"variableName\":\"helveticaNeueCondensed\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"styles/fonts/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./HelveticaNeueLTStd-Bd.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./HelveticaNeueLTStd-MdIt.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"./HelveticaNeueLTStd-It.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./HelveticaNeueLTStd-Md.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./HelveticaNeueLTStd-Roman.woff2\",\"weight\":\"400\",\"style\":\"normal\"}],\"variable\":\"--font-helvetica-neue\",\"display\":\"swap\"}],\"variableName\":\"helveticaNeue\"}");
