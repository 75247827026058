import { tv } from "@otomoro/helpers/mergeClasses";
import { VariantProps } from "tailwind-variants";

export const styles = tv({
	slots: {
		header: 'fixed top-[0] py-2 px-2 lg:px-4 w-full flex justify-between z-10 transition-colors duration-200',
		nav: 'w-full flex items-center justify-between pointer-events-none',
		logoWrapper: 'z-20 relative block pointer-events-auto',
		logo: 'h-[3.5rem] lg:h-4 transition-colors',
		burgerWrapper: 'z-20 lg:hidden cursor-pointer pointer-events-auto',
		burger: 'h-[2.5rem]',
		desktopNav: 'hidden lg:flex absolute flex-col lg:flex-row pt-[7.5rem] lg:pt-[0] top-[0] left-[0] w-full h-[100dvh] lg:h-full lg:static lg:w-fit bg-white lg:bg-transparent',
		list: 'lg:h-full flex w-full mt-auto flex-col lg:flex-row lg:gap-3 px-2 lg:px-[0]',
		listItem: 'py-2 border-b-[1.5px] lg:py-[0] lg:border-none pointer-events-auto relative flex items-center before:content-[""] before:h-[4px] before:bg-mainPink before:opacity-0 before:absolute before:transition-all before:duration-200 before:left-0 before:-top-2 before:z-10 before:w-full',
		textLink: 'z-[2] relative !font-condensed !text-h1-mobile lg:!text-h3 transition-colors duration-200 ease-in-out cursor-default',
		sublinks: 'z-[-1] w-fit bg-white absolute -left-2 top-[calc(100%+1rem)]',
		bgSpan: 'absolute -top-2 h-[calc(100%+3rem)] -left-2 w-[calc(100%+4rem)] bg-white',
		primaryLink: "z-[2]",
		primaryLinkText: '!font-condensed !text-h1-mobile lg:!text-h3 transition-colors duration-200 hover:text-mainPink relative after:content-[""] after:absolute after:-top-2 after:left-0 after:h-[calc(100%+3rem)] after:w-full',
		mobileNavigation: 'lg:hidden absolute flex flex-col pt-[7.5rem] top-[0] left-[0] w-full h-[100dvh] bg-white pointer-events-auto after:content-[""] after:block after:h-[1.5px] after:bg-black after:absolute after:w-[calc(100%-4rem)] after:top-[7.5rem] after:left-2 after:z-10',
		close: 'absolute h-[4rem] w-[4rem] block top-[50%] -translate-y-1/2 right-[1.4rem]',
	},
	variants: {
		active: {
			true: {
				primaryLinkText: "text-mainPink",
				textLink: "text-mainPink",
				listItem: "before:opacity-100"
			}
		}
	}
});

export type ApplicationsVariants = VariantProps<typeof styles>
