import { createElement, forwardRef, HTMLAttributes, ReactNode, Ref } from 'react'
import { AppTextProps, AppText } from '@otomoro/components/shared/AppText'

import { ListVariants, list } from "./AppList.css";

import clsx from "clsx"

type Props = HTMLAttributes<Element> & {
	as?: 'ul' | 'ol' // defaults to ul
	children: ReactNode | Array<ReactNode>
	noWrapper?: boolean
	wrapInText?: boolean
	appTextParams?: AppTextProps | {}
	itemClassName?: string
	variant?: ListVariants['variant']
	gap?: number
}
export const AppList = forwardRef(
	(
		{
			as = 'ul',
			variant,
			children = [],
			noWrapper = false,
			wrapInText = false,
			appTextParams = {},
			gap,
			itemClassName,
			...rest
		}: Props,
		ref: Ref<any>
	) => {
		const { base, items } = list({ variant })

		const listItems = (Array.isArray(children) ? children : [children]).map((item, idx) => {
			return noWrapper ? (
				wrapInText ? (
					<AppText {...appTextParams} key={idx}>
						{item}
					</AppText>
				) : (
					item
				)
			) : (
				<li className={clsx(items({ variant }), itemClassName)} key={idx}>
					{wrapInText ? <AppText {...appTextParams}>{item}</AppText> : item}
				</li>
			)
		})

		return createElement(
			as,
			{
				...rest,
				ref,
				className: clsx(
					base({ variant }),
					rest.className
				),
			},
			listItems
		)
	}
)

AppList.displayName = 'AppList';
