type Props = {
	theme?: 'light' | 'dark'
}

export function TwitterIcon({ theme = 'light' }: Props) {
	return (
		<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				className="transition-all"
				fill={theme === 'dark' ? '#828282' : '#000000'}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.3462 12.9468L7.54782 5.95295L7.55601 5.95951L11.8824 0.946777H10.4367L6.91224 5.02678L4.11342 0.946777H0.321677L4.8014 7.47643L4.80086 7.47587L0.0761719 12.9468H1.52195L5.44028 8.40751L8.55443 12.9468H12.3462ZM3.54057 2.03768L10.273 11.8559H9.12728L2.38943 2.03768H3.54057Z"
			/>
		</svg>
	)
}
