import { tv } from "@otomoro/helpers/mergeClasses";
import { VariantProps } from "tailwind-variants";

export const styles = tv({
	slots: {
		base: 'bg-white pt-1 pb-[0.5rem] px-2 w-max',
		list: 'flex flex-col col-span-3 divide-y-[.15rem] divide-black min-w-[22rem]',
		item: 'relative',
		icon: 'w-[1.7rem] absolute right-0 top-[50%] transform -translate-y-1/2',
	},
});

export type ApplicationsVariants = VariantProps<typeof styles>
