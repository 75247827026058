import { tv } from "@otomoro/helpers/mergeClasses";
import { VariantProps } from "tailwind-variants";

export const styles = tv({
	slots: {
		list: 'flex w-full mt-auto flex-col px-2 divide-y-[1.5px] pb-[3.5rem]',
		listItem: 'py-[1.5rem]',
		icon: 'w-[1.7rem] absolute right-0 top-[50%] transform -translate-y-1/2',
		textLink: 'block w-full relative',
		sublinks: 'absolute top-0 left-0 w-full h-full bg-white z-10',
		primaryLinkText: '',
	},
	variants: {
		active: {
			true: {
				primaryLinkText: 'text-mainPink',
				textLink: 'text-mainPink',
			}
		}
	}
});

export type ApplicationsVariants = VariantProps<typeof styles>
