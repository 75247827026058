import { AppLink } from "@otomoro/components/shared/AppLink";
import { resolveHref } from "@otomoro/sanity/lib/sanity.links";
import { styles } from "./MobileSubLinks.css";
import { ArrowIcon } from "@otomoro/components/icons";
import { AppText } from "@otomoro/components/shared/AppText";

type MobileSubLinksProps = {
	links: Sanity.Default.Schema.NavigationLink[];
	setIsSubMenuOpen: (value: any) => void;
	setIsMenuOpen: (value: any) => void;
};

export const MobileSubLinks = ({ links, setIsSubMenuOpen, setIsMenuOpen }: MobileSubLinksProps) => {
	const { wrapper, list, listItem, button, icon } = styles()

	return (
		<div className={wrapper()}>
			<button className={button()} onClick={() => setIsSubMenuOpen(false)}>
				<ArrowIcon className={icon()} />
				<AppText size="small">Back</AppText>
			</button>
			<ul className={list()}>
				{links.map((link) => (
					link?.internalLink && (
						<li key={link?.label} className={listItem()}>
							<AppLink
								textProps={{
									textTransform: 'uppercase',
									size:"h2",
									family: "condensed",
									weight: "bold",
								}}
								onClick={() => setIsMenuOpen(false)}
								// @ts-ignore
								href={resolveHref(link?.internalLink?._type, link?.internalLink?.slug as any)}
								aria-label={`Go to: ${link?.label}`}>
									{link?.label}
							</AppLink>
						</li>
					)
				))}
			</ul>
		</div>
	);
};
