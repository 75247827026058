import { VariantProps } from 'tailwind-variants';
import { tv } from '@otomoro/helpers/mergeClasses';

export const list = tv({
	slots: {
		base: '',
		items: 'list-none',
	},
	variants: {
		variant: {
			bullet: {
				items: 'list-disc',
			},
			number: {
				items: 'list-decimal',
			},
			checkmark: {
				items: 'list-none',
			},
			flex: {
				base: 'flex',
			},
		},
	}
})

export type ListVariants = VariantProps<typeof list>
