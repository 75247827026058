type Props = {
  theme?: 'light' | 'dark'
}

export function InstagramIcon({ theme = 'light' }: Props) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2.14495C10.136 2.14495 10.3893 2.15295 11.2327 2.19161C12.1047 2.23161 13.0027 2.43028 13.638 3.06561C14.2793 3.70695 14.472 4.59628 14.512 5.47095C14.5507 6.31428 14.5587 6.56761 14.5587 8.70361C14.5587 10.8396 14.5507 11.0929 14.512 11.9363C14.4727 12.8036 14.2693 13.7103 13.638 14.3416C12.9967 14.9829 12.108 15.1756 11.2327 15.2156C10.3893 15.2543 10.136 15.2623 8 15.2623C5.864 15.2623 5.61067 15.2543 4.76733 15.2156C3.90667 15.1763 2.988 14.9683 2.362 14.3416C1.724 13.7036 1.528 12.8056 1.488 11.9363C1.44933 11.0929 1.44133 10.8396 1.44133 8.70361C1.44133 6.56761 1.44933 6.31428 1.488 5.47095C1.52733 4.60695 1.73267 3.69495 2.362 3.06561C3.002 2.42561 3.89467 2.23161 4.76733 2.19161C5.61067 2.15295 5.864 2.14495 8 2.14495ZM8 0.703613C5.82733 0.703613 5.55467 0.712947 4.70133 0.751613C3.46467 0.80828 2.23667 1.15228 1.34267 2.04628C0.445333 2.94361 0.104667 4.16895 0.048 5.40495C0.00933333 6.25828 0 6.53095 0 8.70361C0 10.8763 0.00933333 11.1489 0.048 12.0023C0.104667 13.2376 0.45 14.4689 1.34267 15.3609C2.23933 16.2576 3.46667 16.5989 4.70133 16.6556C5.55467 16.6943 5.82733 16.7036 8 16.7036C10.1727 16.7036 10.4453 16.6943 11.2987 16.6556C12.5347 16.5989 13.764 16.2543 14.6573 15.3609C15.5553 14.4629 15.8953 13.2383 15.952 12.0023C15.9907 11.1489 16 10.8763 16 8.70361C16 6.53095 15.9907 6.25828 15.952 5.40495C15.8953 4.16828 15.5507 2.93961 14.6573 2.04628C13.762 1.15095 12.5313 0.807613 11.2987 0.751613C10.4453 0.712947 10.1727 0.703613 8 0.703613Z"
				className="transition-all"
        fill={theme === 'dark' ? "#828282" : "#000000"}
      />
      <path
        d="M7.9996 4.5957C5.73093 4.5957 3.8916 6.43504 3.8916 8.7037C3.8916 10.9724 5.73093 12.8117 7.9996 12.8117C10.2683 12.8117 12.1076 10.9724 12.1076 8.7037C12.1076 6.43504 10.2683 4.5957 7.9996 4.5957ZM7.9996 11.3704C6.52693 11.3704 5.33293 10.1764 5.33293 8.7037C5.33293 7.23104 6.52693 6.03704 7.9996 6.03704C9.47227 6.03704 10.6663 7.23104 10.6663 8.7037C10.6663 10.1764 9.47227 11.3704 7.9996 11.3704Z"
				className="transition-all"
        fill={theme === 'dark' ? "#828282" : "#000000"}
      />
      <path
        d="M12.2708 5.39266C12.801 5.39266 13.2308 4.96285 13.2308 4.43266C13.2308 3.90246 12.801 3.47266 12.2708 3.47266C11.7406 3.47266 11.3108 3.90246 11.3108 4.43266C11.3108 4.96285 11.7406 5.39266 12.2708 5.39266Z"
				className="transition-all"
        fill={theme === 'dark' ? "#828282" : "#000000"}
      />
    </svg>
  )
}
