import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		wrapper: 'bg-black px-2 md:px-4',
		grid: 'grid grid-cols-3 md:grid-cols-6 gap-x-2 md:gap-x-4',
		column: 'border-t border-white col-span-3 md:col-span-2 pt-2 md:pt-4 mt-4 first:mt-5 md:first:mt-[7rem] md:mt-[7rem] flex flex-col items-start',
		footer:
			'border-t border-davyGrey mt-4 md:mt-6 w-full pt-2 pb-4 md:pb-3 flex md:items-center md:justify-between flex-col md:flex-row',
		footerLinks: 'mt-2 md:mt-0 flex gap-2',
		link: 'mt-[0.5rem] block md:hover:-scale-100 w-fit',
		secondaryLinks: 'mt-2',
		address: 'mt-2 md:mt-3',
		innerWrapper: 'max-w-[172rem] mx-auto',
	},
})

export type StylesVariants = VariantProps<typeof styles>
