interface IconProps {
	className?: string
	color?: string
}

export const ArrowIcon: React.FC<IconProps> = ({ className, color = '#131313' }) => {
	return (
		<svg className={className} viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.9819 5.34077L10.3676 9.9562L9.61705 9.20675L12.953 5.87079L0.981934 5.87079V4.81075L12.953 4.81075L9.61705 1.47585L10.3676 0.725342L14.9819 5.34077Z"
				fill={color}
				stroke={color}
				strokeWidth="0.6"
			/>
		</svg>
	)
}
