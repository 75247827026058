import { AppLink } from "@otomoro/components/shared/AppLink";
import { AppText } from "@otomoro/components/shared/AppText";
import { resolveHref } from "@otomoro/sanity/lib/sanity.links";
import { useLockBodyScroll } from "@uidotdev/usehooks";
import { AnimatePresence, easeInOut, easeOut, motion } from "framer-motion";
import { useState } from "react";
import { styles } from "./MobileNavigation.css";
import { ArrowIcon } from "@otomoro/components/icons";
import { MobileSubLinks } from "./MobileSubLinks";
import { usePathname } from "next/navigation";

type MobileNavigationProps = {
	setIsMenuOpen: (value: any) => void;
	links: any[];
}

export const MobileNavigation = ({setIsMenuOpen, links}: MobileNavigationProps) => {
	const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean | number >(false);
	const pathname = usePathname()
	useLockBodyScroll();

	const {
		listItem,
		list,
		icon,
		textLink,
		sublinks,
		primaryLinkText
	} = styles()

	return (
		<ul className={list()}>
			{links?.map((link: Sanity.Default.Schema.NavigationLink, index: number) => (
				<li className={listItem()} key={`${link?.label}-mobile`}>
					{link?.hasSublinks ? (
						<>
							<AppText
								// @ts-ignore
								className={textLink({active: pathname.includes(link.label?.toLowerCase())})}
								size="h2"
								family="condensed"
								weight="bold"
								tabIndex={0}
								textTransform="uppercase"
								onClick={() => setIsSubMenuOpen(index)}>
								<>
									{link?.label}
									<ArrowIcon className={icon()} />
								</>
							</AppText>

							<AnimatePresence>
								{isSubMenuOpen === index && (
									<motion.div
										initial={{ translateX: '100%', opacity: 0 }}
										animate={{ translateX: 0, opacity: 1}}
										exit={{ translateX: '100%', opacity: 0 }}
										transition={{
											duration: 0.2,
											ease: easeInOut,
										}}
										className={sublinks()}
										onMouseLeave={() => setIsSubMenuOpen(false)}
									>
										<MobileSubLinks
											links={link.sublinks as Sanity.Default.Schema.NavigationLink[]}
											setIsSubMenuOpen={setIsSubMenuOpen}
											setIsMenuOpen={setIsMenuOpen}
										/>
									</motion.div>
								)}
							</AnimatePresence>
						</>
					) : (
						<AppLink
							textProps={{
								textTransform: 'uppercase',
								size:"h2",
								family: "condensed",
								weight: "bold",
								// @ts-ignore
								className: primaryLinkText({ active: pathname.includes(resolveHref(link?.internalLink?._type, link?.internalLink?.slug)) }),
							}}
							onClick={() => setIsMenuOpen(false)}
							// @ts-ignore
							href={resolveHref(link?.internalLink?._type, link?.internalLink?.slug)} aria-label={`Go to: ${link?.label}`}>
								{link?.label}
						</AppLink>
					)}
				</li>
			))}
		</ul>
	)
};
