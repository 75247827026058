'use client'

import { AppLink } from '@otomoro/components/shared/AppLink'
import type { NavigationLayoutProps } from './Navigation.types'
import { Logo } from "@otomoro/components/icons/Logo"
import { BurgerIcon } from "@otomoro/components/icons/Burger"
import { useState } from "react"
import { AnimatePresence, useScroll, motion, useMotionValueEvent, easeInOut } from "framer-motion"
import Link from "next/link"
import clsx from "clsx"
import { usePathname } from "next/navigation";
import { MobileNavigation } from "./MobileNavigation"
import { AppText } from "@otomoro/components/shared/AppText"
import { resolveHref } from "@otomoro/sanity/lib/sanity.links"
import { SubLinks } from "./SubLinks"
import { styles } from "./Navigation.css";
import { CloseIcon } from "@sanity/icons"

export default function NavigationLayout({ data }: NavigationLayoutProps) {
	const { scrollY } = useScroll();
	const [isScrolled, setIsScrolled] = useState(false)
	const pathname = usePathname();
	const darkPaths = [] as any[]
	const strictDarkPaths = ['/']

	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean | number >(false)

	const isDark = darkPaths.some((path) => pathname.includes(path)) || strictDarkPaths.includes(pathname)

	const {
		header,
		nav,
		logoWrapper,
		logo,
		burgerWrapper,
		burger,
		desktopNav,
		list,
		listItem,
		textLink,
		sublinks,
		bgSpan,
		primaryLink,
		primaryLinkText,
		mobileNavigation,
		close,
	} = styles()

	useMotionValueEvent(scrollY, "change", (latest) => {
		if (latest > 0 && !isScrolled) {
			setIsScrolled(true)
		} else if (latest === 0 && isScrolled) {
			setIsScrolled(false)
		}
	})

	if (!data?.navigationLinks) return null

	return (
		<header className={clsx(header(),{ ['bg-white']: isScrolled })}
		>
			<nav className={nav()}>
				<Link href="/" passHref className={logoWrapper()}>
					<span className="sr-only">Go back to Home page</span>
					<Logo
						className={logo()}
						theme={((!isMenuOpen) && (isDark && !isScrolled)) ? 'dark' : 'light'}
						onClick={() => setIsMenuOpen(false)}
					/>
				</Link>

				<div className={burgerWrapper()} onClick={() => setIsMenuOpen((prevState) => !prevState)}>
					{isMenuOpen
						? <CloseIcon className={close()} />
						: <BurgerIcon className={burger()} theme={!isMenuOpen && isDark && !isScrolled ? 'dark' : 'light'} />
					}
				</div>

				{/* Desktop nav */}
				<div className={desktopNav()}>
					<ul className={list()}>
						{data?.navigationLinks.map((link: Sanity.Default.Schema.NavigationLink, index: number) => (
							<li
								key={link?.label}
								className={clsx(listItem({ active: pathname.includes(link?.label?.toLowerCase() || "") }),
									link?.hasSublinks && "before:!w-[calc(100%+4rem)] before:!-left-2",
									isSubMenuOpen === index && 'before:opacity-100'
								)}
								onMouseOver={() => setIsSubMenuOpen(index)}
								onMouseLeave={() => setIsSubMenuOpen(false)}
							>
								{link?.hasSublinks ? (
									<>
										<AppText
											tabIndex={0}
											textTransform="uppercase"
											color={isDark && !isScrolled ? 'white' : 'black'}
											className={clsx(
												// @ts-ignore
												textLink({ active: pathname.includes(link.label?.toLowerCase()) }),
												isSubMenuOpen === index && "!text-mainPink")}
										>
											{link?.label}
										</AppText>

										<AnimatePresence>
											{isSubMenuOpen === index && (
												<motion.div
													initial={{ opacity: 0 }}
													animate={{ opacity: 1}}
													exit={{ opacity: 0 }}
													transition={{
														duration: 0.2,
														ease: easeInOut,
													}}
													className={sublinks()}
													onMouseLeave={() => setIsSubMenuOpen(false)}
												>
													<SubLinks
														links={link.sublinks as Sanity.Default.Schema.NavigationLink[]}
														setIsSubMenuOpen={setIsSubMenuOpen}
													/>
												</motion.div>
											)}
										</AnimatePresence>

										<AnimatePresence>
											{(isSubMenuOpen === index && link?.hasSublinks) && (
												<motion.span
													className={bgSpan()}
													initial={{ opacity: 0 }}
													animate={{ opacity: 1}}
													exit={{ opacity: 0 }}
													transition={{
														duration: 0.2,
														ease: easeInOut,
													}}></motion.span>
											)}
										</AnimatePresence>
									</>
								) : (
									<AppLink
										textProps={{
											textTransform: 'uppercase',

											// @ts-ignore
											className: primaryLinkText({ active: pathname.includes(resolveHref(link?.internalLink?._type, link?.internalLink?.slug) || "") }),
											color: isDark && !isScrolled ? 'white' : 'black',
										}}
										className={primaryLink()}
										// @ts-ignore
										href={resolveHref(link?.internalLink?._type, link?.internalLink?.slug)} aria-label={`Go to: ${link?.label}`}>
											{link?.label}
									</AppLink>
								)}
							</li>
						))}
					</ul>
				</div>

				{/* Mobile nav */}
				<AnimatePresence>
					{(isMenuOpen) && (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							className={mobileNavigation()}>
								<MobileNavigation links={data?.navigationLinks} setIsMenuOpen={setIsMenuOpen} />
						</motion.div>
					)}
				</AnimatePresence>
			</nav>
		</header>
	)
}
