type Props = { className?: string };

export function PlayIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1627 6.25934L5.80267 1.59267C5.30593 1.22893 4.71815 1.00987 4.10451 0.959781C3.49087 0.909693 2.87535 1.03054 2.3262 1.30891C1.77704 1.58728 1.31572 2.01231 0.993372 2.53686C0.671027 3.06141 0.500257 3.66499 0.5 4.28067V13.614C0.50011 14.23 0.670889 14.8338 0.993383 15.3586C1.31588 15.8834 1.77748 16.3086 2.32695 16.5869C2.87642 16.8653 3.49227 16.986 4.10616 16.9356C4.72006 16.8851 5.30797 16.6656 5.80467 16.3013L12.1647 11.6347C12.5868 11.3251 12.9302 10.9204 13.1668 10.4534C13.4034 9.98638 13.5268 9.47021 13.5268 8.94667C13.5268 8.42314 13.4034 7.90696 13.1668 7.43997C12.9302 6.97297 12.5868 6.56827 12.1647 6.25867L12.1627 6.25934ZM11.3733 10.5593L5.01333 15.226C4.71534 15.4438 4.36291 15.5748 3.99505 15.6047C3.62718 15.6345 3.25824 15.562 2.92904 15.3951C2.59985 15.2282 2.32326 14.9735 2.12987 14.6592C1.93648 14.3448 1.83385 13.9831 1.83333 13.614V4.28067C1.82963 3.9109 1.93033 3.54759 2.12385 3.23249C2.31737 2.91738 2.59587 2.66327 2.92733 2.49934C3.20926 2.35593 3.52103 2.28102 3.83733 2.28067C4.26126 2.28229 4.67348 2.41993 5.01333 2.67334L11.3733 7.34001C11.6263 7.5258 11.832 7.76854 11.9738 8.04858C12.1156 8.32863 12.1895 8.63811 12.1895 8.95201C12.1895 9.2659 12.1156 9.57538 11.9738 9.85543C11.832 10.1355 11.6263 10.3782 11.3733 10.564V10.5593Z"
        fill="white"
      />
    </svg>
  );
}
