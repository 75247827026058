import {
  TikTokIcon,
  DiscordIcon,
  TwitterIcon,
  YouTubeIcon,
  LinkedInLogo,
  InstagramIcon,
} from "@otomoro/components/icons/socials";
import { AppText } from "@otomoro/components/shared/AppText";
import { styles } from "./NavigationSocials.css";
import clsx from "clsx";

type Props = {
  socials?: {
    socialMediaType?: string | undefined;
    url?: string | undefined;
  }[] | undefined;
  catchphrase?: string;
  inline?: boolean;
  className?: string;
  inBanner?: boolean;
};

const socialsMap = {
  'X': {
    component: <TwitterIcon theme="dark" />,
		title: 'X',
  },
  'tiktok': {
    component: <TikTokIcon theme="dark" />,
		title: 'TikTok',
  },
  'instagram': {
    component: <InstagramIcon theme="dark" />,
		title: 'Instagram',
  },
  'youtube': {
    component: <YouTubeIcon theme="dark" />,
		title: 'YouTube',
  },
  'linkedin': {
    component: <LinkedInLogo theme="dark" />,
		title: 'LinkedIn',
  },
  'discord': {
    component: <DiscordIcon theme="dark" />,
		title: 'Discord',
  },
} as { [key: string]: { component: JSX.Element, title: string } };

export function NavigationSocials({ socials, className }: Props) {
	const {icon, list, link} = styles()

	const renderIcon = (socialMediaType: string) => {
		if (!socialsMap[socialMediaType]){
			// console.error(`No social media icon found for ${socialMediaType}`);
			return null;
		}

		return socialsMap[socialMediaType].component;
	}

  return (
    <div className={className}>
      <ul className={list()}>
        {socials?.map((item: any) => (
          <li key={item.socialMediaType}>
            <a
							className={clsx("[&:hover>span>svg>*]:fill-mainPink", link())}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              title={item.socialMediaType}
            >
							<span className={icon()}>
								{renderIcon(item.socialMediaType)}
							</span>
							<AppText className="sr-only" size="base" weight="medium" color="white">{item.title || socialsMap[item.socialMediaType].title}</AppText>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
