interface IconProps {
	className?: string
	theme?: 'light' | 'dark'
	onClick?: () => void
}

export const LogoHead: React.FC<IconProps> = ({ className, theme = 'light', onClick }) => {
	return (
		<svg className={className} viewBox="0 0 160 189" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.8008 24.8538L9.5788 15.3935L9.51025 15.3935L6.08263 24.8538L0.735531 24.8538L7.07664 10.0122L7.07664 0.380538L12.0124 0.380538L12.0124 10.0122L18.3535 24.8538L12.8008 24.8538Z" fill="#131313"/>
			<path d="M34.5 24.8538L25.3825 24.8538C23.326 24.8538 21.7264 24.3282 20.5839 23.2771C19.4413 22.2488 18.87 20.6721 18.87 18.5469C18.87 16.9017 19.2014 15.5535 19.8641 14.5023C20.5267 13.4741 21.555 12.8114 22.9489 12.5143L22.9489 12.4458C21.715 12.263 20.8124 11.8631 20.2411 11.2461C19.6698 10.6291 19.3156 9.63512 19.1785 8.26407C19.1328 7.80705 19.0986 7.30433 19.0757 6.75591C19.0529 6.23035 19.03 5.63623 19.0072 4.97355C18.9615 3.67106 18.8929 2.67704 18.8015 1.99152C18.6644 1.306 18.3673 0.837555 17.9103 0.586195L17.9103 0.380537L23.2574 0.380538C23.5088 0.723302 23.6687 1.11177 23.7373 1.54593C23.8058 2.00295 23.8515 2.47139 23.8744 2.95125L24.0115 7.6471C24.0572 8.60683 24.2971 9.36091 24.7313 9.90933C25.1654 10.4577 25.8967 10.732 26.925 10.732L29.5642 10.732L29.5642 0.380539L34.5 0.380539L34.5 24.8538ZM29.5642 14.1596L27.5077 14.1596C26.3194 14.1596 25.4054 14.4338 24.7656 14.9822C24.1257 15.5535 23.8058 16.5018 23.8058 17.8271C23.8058 20.0894 24.9484 21.2205 27.2334 21.2205L29.5642 21.2205L29.5642 14.1596Z" fill="#131313"/>
			<path d="M35.9491 24.8538L35.9491 20.8092L41.2962 20.8092L41.2962 0.380538L46.232 0.380538L46.232 20.8092L51.5791 20.8092L51.5791 24.8538L35.9491 24.8538Z" fill="#131313"/>
			<path d="M63.3914 8.024L63.3914 7.3042C63.3914 6.73293 63.3457 6.20736 63.2543 5.72749C63.1629 5.27048 62.9915 4.88201 62.7401 4.5621C62.4888 4.24219 62.1574 3.99083 61.7461 3.80803C61.3119 3.62522 60.775 3.53381 60.1351 3.53381C59.3811 3.53381 58.707 3.77375 58.1128 4.25362C57.4959 4.75633 57.1874 5.52184 57.1874 6.55012C57.1874 7.09854 57.2674 7.56699 57.4273 7.95545C57.5644 8.36676 57.8043 8.73238 58.1471 9.05229C58.4899 9.3722 58.9469 9.65784 59.5182 9.90919C60.0666 10.1834 60.7521 10.4576 61.5747 10.7318C62.6716 11.0974 63.6199 11.4973 64.4197 11.9315C65.2194 12.3657 65.8935 12.8684 66.442 13.4396C66.9675 14.0338 67.356 14.7079 67.6073 15.4619C67.8587 16.2389 67.9844 17.1301 67.9844 18.1355C67.9844 20.5577 67.3103 22.3629 65.9621 23.5511C64.6139 24.7394 62.763 25.3335 60.4093 25.3335C59.3125 25.3335 58.3071 25.2078 57.393 24.9565C56.4561 24.7279 55.6564 24.3395 54.9937 23.7911C54.3082 23.2655 53.7826 22.58 53.417 21.7345C53.0285 20.9119 52.8343 19.9179 52.8343 18.7525L52.8343 18.0669L57.5644 18.0669C57.5644 19.2323 57.7701 20.1235 58.1814 20.7405C58.5927 21.3803 59.2782 21.7002 60.238 21.7002C60.7864 21.7002 61.2434 21.6202 61.609 21.4603C61.9746 21.3003 62.2717 21.0832 62.5002 20.809C62.7058 20.5577 62.8544 20.2606 62.9458 19.9179C63.0143 19.5751 63.0486 19.2209 63.0486 18.8553C63.0486 18.1012 62.8887 17.4614 62.5687 16.9358C62.2488 16.4331 61.5633 15.9647 60.5122 15.5305L56.7075 13.8852C55.7706 13.4739 55.0051 13.0398 54.411 12.5827C53.8169 12.1486 53.3484 11.6687 53.0057 11.1431C52.6401 10.6404 52.3887 10.0806 52.2516 9.4636C52.1145 8.84663 52.0459 8.16111 52.0459 7.40703C52.0459 4.82489 52.8 2.93969 54.3082 1.75145C55.7935 0.58606 57.8729 0.00336556 60.5464 0.00336579C63.3342 0.00336603 65.3337 0.608911 66.5448 1.82001C67.733 3.0311 68.3272 4.76776 68.3272 7.02999L68.3271 8.024L63.3914 8.024Z" fill="#131313"/>
			<path d="M75.2886 0.380539L75.2886 24.8538L70.3528 24.8538L70.3528 0.380539L75.2886 0.380539Z" fill="#131313"/>
			<path d="M100.11 24.8538L92.7747 24.8538L89.2786 7.57855L89.21 7.57855L85.6796 24.8538L78.3787 24.8538L78.3787 0.380537L82.9032 0.380537L82.9032 19.918L82.9717 19.918L87.3248 0.380538L91.1638 0.380538L95.5168 19.918L95.5854 19.918L95.5854 0.380539L100.11 0.380539L100.11 24.8538Z" fill="#131313"/>
			<path d="M102.356 24.8538L102.356 20.8092L111.096 20.8092L111.096 15.0508L102.87 15.0508L102.87 11.0062L111.096 11.0062L111.096 4.42513L102.013 4.42513L102.013 0.380538L116.032 0.380539L116.032 24.8538L102.356 24.8538Z" fill="#131313"/>
			<path d="M134.388 24.8538L129.452 24.8538L129.452 15.4964L123.694 15.4964L123.694 24.8538L118.758 24.8538L118.758 0.380538L123.694 0.380538L123.694 11.2461L129.452 11.2461L129.452 0.380539L134.388 0.380539L134.388 24.8538Z" fill="#131313"/>
			<path d="M141.657 16.5931C141.657 18.3754 141.874 19.6665 142.308 20.4663C142.72 21.2889 143.485 21.7002 144.605 21.7002C145.222 21.7002 145.736 21.5288 146.147 21.1861C146.536 20.8662 146.844 20.3292 147.073 19.5751C147.301 18.8439 147.461 17.8727 147.553 16.6616C147.644 15.4734 147.69 14.0109 147.69 12.2743C147.69 10.4233 147.633 8.92661 147.518 7.78407C147.381 6.64153 147.187 5.75035 146.936 5.11052C146.661 4.49355 146.33 4.07081 145.942 3.8423C145.53 3.63665 145.062 3.53381 144.536 3.53381C144.102 3.53381 143.702 3.60237 143.337 3.73947C142.948 3.89943 142.617 4.19649 142.343 4.63065C142.068 5.08767 141.851 5.71607 141.691 6.51585C141.531 7.33848 141.451 8.41247 141.451 9.73781L136.516 9.73781C136.516 8.41246 136.618 7.15567 136.824 5.96743C137.03 4.77918 137.418 3.73947 137.99 2.84829C138.538 1.95711 139.326 1.26016 140.355 0.757441C141.36 0.254724 142.674 0.0033656 144.296 0.00336574C146.147 0.0033659 147.621 0.300426 148.718 0.894546C149.815 1.48867 150.66 2.34558 151.254 3.46527C151.826 4.58496 152.203 5.92173 152.386 7.47558C152.546 9.02944 152.625 10.7432 152.625 12.617C152.625 14.4679 152.546 16.1703 152.386 17.7242C152.203 19.3009 151.826 20.6491 151.254 21.7688C150.66 22.8885 149.815 23.7568 148.718 24.3738C147.621 25.0136 146.147 25.3335 144.296 25.3335C142.537 25.3335 141.154 25.0479 140.149 24.4766C139.121 23.9053 138.355 23.1741 137.852 22.2829C137.35 21.4146 137.041 20.4663 136.927 19.438C136.79 18.4325 136.721 17.4842 136.721 16.5931L141.657 16.5931Z" fill="#131313"/>
			<circle cx="156.757" cy="2.88839" r="2.50802" fill="#131313"/>
			<circle cx="156.757" cy="22.1162" r="2.50802" fill="#131313"/>
			<path d="M2.69985 156.261L2.69985 136.531L45.3361 136.531L45.3361 108.442L5.20787 108.442L5.20787 88.712L45.3361 88.712L45.3361 56.6094L1.02785 56.6094L1.02785 36.8797L69.4131 36.8797L69.4131 156.261L2.69985 156.261Z" fill="#131313"/>
			<path d="M158.973 156.261L114.497 156.261C104.465 156.261 96.6624 153.697 91.089 148.57C85.5156 143.554 82.729 135.863 82.729 125.496C82.729 117.471 84.3452 110.894 87.5778 105.766C90.8104 100.75 95.8264 97.5179 102.626 96.0688L102.626 95.7344C96.6067 94.8427 92.2037 92.892 89.417 89.8824C86.6303 86.8728 84.9026 82.0239 84.2338 75.3359C84.0108 73.1065 83.8437 70.6543 83.7322 67.9791C83.6207 65.4153 83.5092 62.5171 83.3978 59.2846C83.1748 52.931 82.8404 48.0821 82.3946 44.7381C81.7258 41.3941 80.2767 39.109 78.0473 37.8829L78.0473 36.8797L104.131 36.8797C105.357 38.5517 106.137 40.4466 106.472 42.5645C106.806 44.7938 107.029 47.0789 107.14 49.4197L107.809 72.3263C108.032 77.0079 109.202 80.6863 111.32 83.3616C113.438 86.0368 117.005 87.3744 122.021 87.3744L134.896 87.3744L134.896 36.8797L158.973 36.8797L158.973 156.261ZM134.896 104.094L124.864 104.094C119.067 104.094 114.609 105.432 111.488 108.107C108.366 110.894 106.806 115.52 106.806 121.985C106.806 133.02 112.379 138.538 123.526 138.538L134.896 138.538L134.896 104.094Z" fill="#131313"/>
			<line x1="1.1001" y1="183.925" x2="158.901" y2="183.925" stroke="#131313" strokeWidth="9.62199"/>
		</svg>
	)
}
