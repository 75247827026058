'use client';

import { QueryResponseInitial, useQuery } from '@sanity/react-loader';
import { SanityDocument } from 'next-sanity';

import {footerQuery} from '@otomoro/sanity/lib/queries/layout/footer.groq';
import FooterLayout from "./Footer.layout";

export default function FooterPreview({
  initial,
}: {
  initial: QueryResponseInitial<SanityDocument[]>;
}) {
	const { data } = useQuery<SanityDocument[] | null>(
    footerQuery,
    {},
    { initial }
  );

	return data ? (
    <FooterLayout data={data as any} />
  ) : (
    <div className="bg-red-100">No posts found</div>
  );
}
