'use client';

import { QueryResponseInitial, useQuery } from '@sanity/react-loader';
import { SanityDocument } from 'next-sanity';

import { navigationLinksQuery } from "@otomoro/sanity/lib/queries/layout/navigationLinks.groq";
import NavigationLayout from "./Navigation.layout";

export default function NavigationPreview({
  initial,
}: {
  initial: QueryResponseInitial<SanityDocument[]>;
}) {
	const { data } = useQuery<SanityDocument[] | null>(
    navigationLinksQuery,
    {},
    { initial }
  );

	return data ? (
    <NavigationLayout data={data as any} />
  ) : (
    <div className="bg-red-100">No posts found</div>
  );
}
