'use client'

// import useTranslation from "next-translate/useTranslation";
import { Logo } from "@otomoro/components/icons/Logo"
import { AppLink } from "@otomoro/components/shared/AppLink"
import { AppList } from "@otomoro/components/shared/AppList"
import { AppText } from "@otomoro/components/shared/AppText"
import { resolveHref } from "@otomoro/sanity/lib/sanity.links"
import { PortableText, PortableTextProps } from "next-sanity"
import { NavigationSocials } from "../NavigationSocials"
import { styles } from "./Footer.css"
import { LinkInternal } from "@otomoro/components/shared/LinkInternal"
import { LinkExternal } from "@otomoro/components/shared/LinkExternal"
import { Button } from "@otomoro/components/shared/Button"
import { Arrow } from "@otomoro/components/icons/Arrow"
import { SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { downloadValidationSchema, Inputs } from "./schema"
import { sendEmail } from "./actions"
import { useEffect } from "react"
import clsx from "clsx"

export type FooterLayoutProps = {
	data: {
		footer: Omit<Sanity.Default.Schema.Footer, "primaryLinks"> & {
			primaryLinks: {
				link: {
					title: string
					reference: {
						_type: string
						pageTitle: string
						slug: string
					}
					anchor?: string
				}
			}[]
			legalLinks: {
				_type: string
				pageTitle: string
				slug: string
			}[]
			address: PortableTextProps['value']
		}
		socials: Sanity.Default.Schema.Socials
	}
}

export default function FooterLayout({ data }: FooterLayoutProps) {
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitSuccessful, isSubmitting },
		reset,
		setError,
	} = useForm<Inputs>({
		resolver: yupResolver(downloadValidationSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		const res = await sendEmail(data)

		if (res?.status && res?.status !== 'success') {
			setError('email', {
				type: 'server',
				message: res?.message,
			})
		}
	}

	useEffect(() => {
		if (!isSubmitSuccessful) return
		setTimeout(() => {
			reset()
		}, 3000)
	}, [isSubmitSuccessful, reset])

	const {
		column,
		footer: footerStyles,
		wrapper,
		link: linkStyles,
		grid,
		address,
		footerLinks,
		innerWrapper,
	} = styles()
	const { footer, socials } = data;

	const components = {
		block: {
			normal: ({ children }: any) => (
				<AppText
					as="p"
					color="white"
					size="base"
					weight="medium"
					className="mt-2 first:mt-0"
				>
					{children}
				</AppText>
			),
		},
		marks: {
			highlight: ({ children }: any) => <span className="text-mainPink">{children}</span>,
			annotationLinkInternal: ({ value, children }) => <LinkInternal color="white" size="base" value={value}>{children}</LinkInternal>,
      annotationLinkExternal: ({ value, children }) => <LinkExternal color="white" size="base" value={value}>{children}</LinkExternal>,
			annotationLinkEmail: ({ value, children }) => <AppLink textProps={{ color: "white", size: "base", family: 'helvetica' }} href={`mailto:${value.email}`}><>{children}</></AppLink>,
			annotationLinkPhone: ({ value, children }) => <AppLink textProps={{ color: "white", size: "base" }} href={`tel:${value.url}`}><>{children}</></AppLink>,
		}
	} as PortableTextProps['components']

	return (
		<footer className={wrapper()}>
			<div className={innerWrapper()}>
				<div className={grid()}>
					<div className={column()}>
						<Logo className="h-[3.5rem] md:h-[4.5rem] w-auto" theme="dark" />

						<div className={address()}>
							<PortableText value={footer?.address as PortableTextProps['value']} components={components}/>
						</div>
					</div>
					<div className={column()}>
						<AppList className="flex flex-col space-y-[0.5rem] md:space-y-0">
							{footer?.primaryLinks?.map(({link}, index) => (
								<AppLink
									className={linkStyles()}
									href={resolveHref(link?.reference._type, link?.reference.slug, link?.anchor)}
									key={index}
									textProps={{
										family: "condensed",
										textTransform: "uppercase",
										color: "white",
										className: "!text-h3-mobile md:!text-h2 hover:text-mainPink transition-colors duration-200",
									}}
								>
									{link?.title}
								</AppLink>
							))}
						</AppList>
					</div>

					<div className={column()}>
						 <AppText as="label" htmlFor="" size="footerTitle" family="condensed" color="white">
							SIGN UP FOR <br className="hidden md:block"/>OUR <AppText size="footerTitle" family="condensed" color="mainPink">NEWSLETTER</AppText>
						</AppText>
						<form className="flex gap-x-1 items-start mt-[1.5rem] relative" onSubmit={handleSubmit(onSubmit)}>
							<input
								placeholder="E-mail"
								type="text"
								id=""
								disabled={isSubmitting}
								className={clsx(
									"bg-darkGrey placeholder:font-medium placeholder:text-oldSilver py-[1.2rem] rounded-full w-full text-input-mobile md:text-base  px-[1.5rem] md:px-2 text-white focus:outline-none",
									errors.email?.message ? '!border-redError' : '',
									isSubmitSuccessful ? '!border-mainPink' : ''
								)}
								{...register('email')}
							/>
							<Button single type="submit">
								<Arrow orientation="top-right" color="black" height={10} width={15} />
							</Button>

							<div className="absolute bottom-0 left-0 translate-y-full">
								{errors.email?.message && (
									<span className="block text-redError text-s mt-[0.5rem]">{errors.email?.message}</span>
								)}
								{isSubmitSuccessful && (
									<span className="block text-mainPink text-s mt-[0.5rem]">
										Thank you for subscribing!
									</span>
								)}
							</div>
						</form>

						<div className="mt-3 md:mt-[4.2rem]">
							<AppText as="strong" size="footerTitle" family="condensed" color="white">
								FOLLOW US ON <br className="hidden md:block"/><AppText size="footerTitle" family="condensed" color="mainPink">SOCIAL MEDIA</AppText>
							</AppText>

							{socials?.socialMedia && (
								<NavigationSocials socials={socials?.socialMedia} />
							)}
						</div>
					</div>
				</div>
				<div className={footerStyles()}>
					<AppText as="p" color="davyGrey" className="!text-xs-mobile md:!text-s">© {new Date().getFullYear()} RE:CHEMISTRY</AppText>
					<AppList className={footerLinks()}>
						{footer?.legalLinks?.map((link, index) => (
							<AppLink
								href={resolveHref(link._type, link.slug)}
								key={index}
								textProps={{
									color: "davyGrey",
									className: "!text-xs-mobile md:!text-s hover:text-white transition-colors duration-200",
								}}
							>
								{link.pageTitle}
							</AppLink>
						))}
					</AppList>
				</div>
			</div>
		</footer>
	)
}
