import { AppLink } from '@otomoro/components/shared/AppLink';
import { ReactNode } from "react";
import { resolveHref } from '@otomoro/sanity/lib/sanity.links';
import { TextVariants } from "../AppText/AppText.css";

interface LinkInternalProps{
  value: any,
  children?: ReactNode;
	color?: string;
	size?: TextVariants['size'];
}

export const LinkInternal: React.FC<LinkInternalProps> = ({
  value,
  children,
	color,
	size,
}) => {
  return (
    <AppLink
      href={resolveHref(value._type, value.slug)}
			textProps={{ color, size }}
    >
      <>{children}</>
    </AppLink>
  );
};
