import { tv } from '@otomoro/helpers/mergeClasses'
import { VariantProps } from 'tailwind-variants'

export const styles = tv({
	slots: {
		link: 'text-white flex items-center gap-x-1',
		list: 'flex gap-x-2 mt-[1.5rem]',
		icon: 'w-[3.6rem] h-[3.6rem] inline-block fill-current flex items-center justify-center rounded-full bg-darkGrey',
	},
})

export type StylesVariants = VariantProps<typeof styles>
