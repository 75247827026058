import { groq } from "next-sanity";

export const footerQuery = groq`
  {
    "footer": *[_type == "footer"][0] {
			address,
			primaryLinks[]{
				link{
					title,
					reference->{
						_type,
						pageTitle,
						"slug": slug.current,
					},
					anchor
				}
			},
      legalLinks[]->{
        _type,
        pageTitle,
        "slug": slug.current
      },
    },
    "socials": *[_type == "socials"][-1] {
      socialMedia
    }
  }`;
