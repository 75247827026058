import colors from '@otomoro/styles/variables/colors.module.scss';

type Props = {
  className?: string;
  theme?: string;
};

export function DiscordIcon({ theme = 'light' }: Props) {
  return (
    <svg
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4047 2.16395C17.0932 1.56224 15.6875 1.1191 14.2175 0.865381C14.1909 0.860238 14.1644 0.873095 14.1498 0.897095C13.9689 1.21852 13.7684 1.63852 13.6287 1.96767C12.0472 1.7311 10.4744 1.7311 8.9255 1.96767C8.78579 1.63081 8.5775 1.21852 8.39664 0.897095C8.38207 0.873095 8.3555 0.861095 8.32893 0.865381C6.85979 1.11824 5.45407 1.56138 4.14178 2.16395C4.13064 2.16824 4.12035 2.17681 4.11435 2.1871C1.44778 6.1711 0.716636 10.0574 1.07492 13.8957C1.07664 13.9145 1.08692 13.9325 1.10149 13.9437C2.86121 15.2363 4.56521 16.0205 6.23836 16.54C6.26493 16.5485 6.29321 16.5383 6.31036 16.516C6.70636 15.976 7.05864 15.406 7.36122 14.8068C7.37922 14.7717 7.36207 14.7297 7.32522 14.716C6.7655 14.5034 6.23321 14.2445 5.72064 13.9514C5.68036 13.9274 5.67693 13.87 5.71379 13.8417C5.82178 13.7611 5.92979 13.6771 6.03264 13.5922C6.0515 13.5768 6.07721 13.5734 6.0995 13.5837C9.46636 15.1205 13.1109 15.1205 16.4375 13.5837C16.4598 13.5734 16.4855 13.576 16.5052 13.5922C16.6081 13.6771 16.7161 13.762 16.8249 13.8425C16.8627 13.87 16.8601 13.9283 16.8189 13.9523C16.3064 14.2514 15.7741 14.5051 15.2135 14.716C15.1767 14.7297 15.1612 14.7725 15.1784 14.8077C15.4869 15.406 15.8401 15.976 16.2284 16.516C16.2447 16.5391 16.2738 16.5485 16.3004 16.5408C17.9812 16.0205 19.6861 15.2363 21.4449 13.9445C21.4604 13.9334 21.4698 13.9163 21.4715 13.8974C21.9001 9.45996 20.7532 5.60539 18.4304 2.18881C18.4261 2.17681 18.4158 2.16824 18.4047 2.16395ZM7.86436 11.5591C6.85036 11.5591 6.0155 10.6282 6.0155 9.48567C6.0155 8.3431 6.83407 7.41224 7.86436 7.41224C8.90236 7.41224 9.7295 8.35082 9.71322 9.48567C9.71322 10.6291 8.89379 11.5591 7.86436 11.5591ZM14.7001 11.5591C13.6869 11.5591 12.8512 10.6282 12.8512 9.48567C12.8512 8.3431 13.6698 7.41224 14.7001 7.41224C15.7381 7.41224 16.5652 8.35082 16.5489 9.48567C16.5489 10.6291 15.7381 11.5591 14.7001 11.5591Z"
				className="transition-all"
        fill={theme === 'dark' ? "#828282" : "#000000"}
      />
    </svg>
  );
}
