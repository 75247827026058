type Props = {
	theme?: 'light' | 'dark'
}

export function LinkedInLogo({ theme = 'light' }: Props) {
	return (
		<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				className="transition-all"
				fill={theme === 'dark' ? '#828282' : '#000000'}
				d="M3.51662 2.2807C3.51644 2.63431 3.3758 2.97336 3.12564 3.22326C2.87548 3.47317 2.53629 3.61347 2.18269 3.6133C1.82909 3.61312 1.49004 3.47248 1.24013 3.22232C0.990219 2.97216 0.849921 2.63297 0.850098 2.27937C0.850275 1.92577 0.990912 1.58672 1.24107 1.33681C1.49123 1.0869 1.83042 0.946601 2.18402 0.946778C2.53763 0.946954 2.87668 1.08759 3.12659 1.33775C3.37649 1.58791 3.51679 1.9271 3.51662 2.2807ZM3.55661 4.60057H0.890096V12.9468H3.55661V4.60057ZM7.76971 4.60057H5.11653V12.9468H7.74305V8.56702C7.74305 6.12716 10.9229 5.9005 10.9229 8.56702V12.9468H13.5561V7.6604C13.5561 3.5473 8.84965 3.70062 7.74305 5.72051L7.76971 4.60057Z"
			/>
		</svg>
	)
}
