import colors from '@otomoro/styles/variables/colors.module.scss'

type Props = {
  theme?: 'light' | 'dark'
}

export function TikTokIcon({ theme = 'light' }: Props) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.18 7.15624C13.6285 7.15624 12.1901 6.66011 11.0165 5.81726V11.8993C11.0165 14.937 8.54528 17.4075 5.50826 17.4075C4.33466 17.4075 3.24591 17.0376 2.35157 16.4095C0.931342 15.4121 0 13.7627 0 11.8993C0 8.86153 2.47125 6.39028 5.50899 6.39028C5.76141 6.39028 6.00802 6.41131 6.25102 6.44395V7.15262V9.4991C6.016 9.42584 5.76794 9.38305 5.50899 9.38305C4.12213 9.38305 2.99349 10.5117 2.99349 11.8993C2.99349 12.8654 3.54186 13.7046 4.34264 14.1261C4.69153 14.3096 5.08829 14.4148 5.50971 14.4148C6.86466 14.4148 7.97008 13.3369 8.02085 11.9936L8.02303 0H11.0158C11.0158 0.259673 11.0412 0.512818 11.0862 0.759435C11.2972 1.89968 11.974 2.87816 12.9118 3.48963C13.5647 3.91541 14.3437 4.1642 15.1793 4.1642L15.18 7.15624Z"
				className="transition-all"
        fill={theme === 'dark' ? "#828282" : "#000000"}
      />
    </svg>
  )
}
